<template>
  <div>
    <h1>Live Data</h1>
    
    <div v-for="(data, key) in dataStreams" :key="key">
      <h2>{{ key.charAt(0).toUpperCase() + key.slice(1) }}</h2>
      <p>{{ data }} A</p>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dataStreams: {},
    };
  },
  methods: {

    initWebSocket(path) {
      const socket = new WebSocket(`ws://192.168.1.200:1880/ws/${path}`);

      socket.onmessage = (event) => {
        this.dataStreams[path] = JSON.parse(event.data);
      };

      socket.onopen = () => console.log(`Verbonden met websocket voor ${path}`);
      socket.onclose = () => console.log(`Websocket gesloten voor ${path}`);

      return socket;
    },
  },
  mounted() {
    // Voorbeeld met meerdere dynamische paths
    const paths = ['P1ConsumptionL1', 'P1ConsumptionL2', 'P1ConsumptionL3'];
    paths.forEach((path) => {
      this.dataStreams[path] = null;
      this[`socket_${path}`] = this.initWebSocket(path);
    });
  },
  beforeUnmount() {
    Object.keys(this.dataStreams).forEach((path) => {
      if (this[`socket_${path}`]) {
        this[`socket_${path}`].close();
      }
    });
  }
};


</script>